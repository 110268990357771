/* Customized Defaults */
$primary: #000;
$font-family-sans-serif: "Open Sans";

@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');


.no-display { display: none }
.error { color: red; font-size: .85rem; margin-top: .3rem;}
.pointer { cursor: pointer }

.navbar-brand > img { margin-right: 12px; }

#connection {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}

::cue {
  color:white;
  font-size: 150%;
  background-color: transparent;
  text-shadow: 0px 0px 16px #000;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.tab-content img { max-width: 100%; }
